import React, {Suspense, lazy} from "react";
import {Route, Routes} from "react-router-dom";

const AKAHoldingPage = lazy(() => import("./views/AKAHoldingPage"));
const AKAPrivacyPage = lazy(() => import("./views/AKAPrivacyPage"));
const AKACookiePage = lazy(() => import("./views/AKACookiePage"));
const AKATermsPage = lazy(() => import("./views/AKATermsPage"));

export default function Routing() {
	let _path = "/";

	return (
		<Suspense fallback={<div className="loading">Loading...</div>}>
			<Routes>
				<Route
					exact
					path={`${_path}`}
					element={
						<Suspense
							fallback={
								<>
									<div className="loading">Loading...</div>
								</>
							}
						>
							{" "}
							<AKAHoldingPage />{" "}
						</Suspense>
					}
				/>
				<Route
					exact
					path={`${_path}privacy-and-policy`}
					element={
						<Suspense
							fallback={
								<>
									<div className="loading">Loading...</div>
								</>
							}
						>
							{" "}
							<AKAPrivacyPage />{" "}
						</Suspense>
					}
				/>
				<Route
					exact
					path={`${_path}cookie-policy`}
					element={
						<Suspense
							fallback={
								<>
									<div className="loading">Loading...</div>
								</>
							}
						>
							{" "}
							<AKACookiePage />{" "}
						</Suspense>
					}
				/>
				<Route
					exact
					path={`${_path}terms-and-conditions`}
					element={
						<Suspense
							fallback={
								<>
									<div className="loading">Loading...</div>
								</>
							}
						>
							{" "}
							<AKATermsPage />{" "}
						</Suspense>
					}
				/>
			</Routes>
		</Suspense>
	);
}
